import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

// Redux
import { updateUserProfile } from 'app/dispatcher/profileDispatcher';

// Components
import { Button, FormInput } from 'app/seedly-component-library';

import * as S from './styles';

const InputNameForm = () => {
  const dispatch = useDispatch();
  const description = useSelector(state => state.profile.user.description);
  const links = useSelector(state => state.profile.user.links);
  const formik = useFormik({
    initialValues: { name: '' },
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Required';
      } else if (values.name.length < 3) {
        errors.name = 'Name must be at least 3 characters long';
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(
        updateUserProfile(
          { name: values.name, description, links },
          {
            onFailure: () => setSubmitting(false),
            onSuccess: () => window.location.reload(),
          },
        ),
      );
    },
  });

  const {
    handleSubmit,
    handleReset,
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <div>
      <S.Scene>
        <S.SceneTitle>Oops, we need your name!</S.SceneTitle>
        <S.SceneDesc>
          To ensure that the community is a friendly place, please enter your
          display name.
        </S.SceneDesc>
      </S.Scene>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <S.Fields>
          <FormInput
            type="text"
            name="name"
            onChange={e => setFieldValue('name', e.target.value)}
            onBlur={setFieldTouched}
            value={values.name}
            error={errors.name && touched.name && errors.name}
            label="Name"
            placeholder="Your name"
            disabled={isSubmitting}
            info="*Min 3 Characters"
            width="100%"
          />
        </S.Fields>
        <div>
          <S.SubmitButtonWrapper>
            <Button type="submit" isLoading={isSubmitting}>
              Done
            </Button>
          </S.SubmitButtonWrapper>
        </div>
      </form>
    </div>
  );
};

export default InputNameForm;
