import styled from 'styled-components';

export const SeedlyLogo = styled.img`
  height: 36px;
  width: 74px;
  object-fit: contain;
`;

export const Fields = styled.div`
  margin-top: 36px;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
  margin-bottom: 32px;
`;

export const Scene = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SceneTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  padding-top: 30px;
`;

export const SceneDesc = styled.div`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.neutral5};
`;
