import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { Modal as BaseModal } from 'app/seedly-component-library';
import { imageUrl } from 'theme';
import InputNameForm from './InputNameForm';

import * as S from './styles';

const NameModal = () => {
  const isNameModalOpen = useSelector(state => state.modal.isNameModalOpen);

  return (
    <BaseModal
      ariaHideApp={false}
      isOpen={isNameModalOpen}
      label="name-auth"
      renderHeaderLeft={() => (
        <S.SeedlyLogo src={imageUrl.seedlyLogo} alt="Seedly logo" />
      )}
    >
      <InputNameForm />
    </BaseModal>
  );
};

export default NameModal;
